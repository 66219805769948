import React, { useState } from "react";
import axios from "axios";

import { Script } from 'gatsby';

import Select from 'react-select';

import { contactForm, contactForm_wrapper, contactForm_cta, contactForm_ctaWrapper,
  contactForm_form, contactForm_formCta, contactForm_fields, contactForm_fields_first,
  contactForm_fields_last, contactForm_fields_email, contactForm_fields_phone, 
  contactForm_fields_city, contactForm_fields_state, contactForm_fields_prof,
  contactForm_fields_spec, contactForm_fields_comments, contactForm_fields_required,
  contactForm_fields_submit, contactForm_success, contactForm_successText } from './contact-form.module.scss';

const ContactForm = ({data}) => {
    const [submitted, setSubmitted] = useState(false)
    const [notification, setNotification] = useState('')

    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });

    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });

      if (ok) {
        setSubmitted(true)
        form.reset();
      }
    };

    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });

      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LdsOx4iAAAAAIsCht7EfyhaR5i_8hES02IoWyju', {
          action: 'submit'
        })
        .then(function(token) {
            // Add your logic to submit to your backend server here.
          document.getElementById('captchaResponse').value = token;
          
          axios({
            method: "post",
            url: "https://getform.io/f/413c3290-7ee4-4286-b080-8730bc98a8a1",
            data: new FormData(form),
          })
          .then(r => {
              handleServerResponse(true, "Thanks!", form);
            })
            .catch(r => {
              handleServerResponse(false, r.response.data.error, form);
            });
        });
      });
    };

    const profList = [
        { value:"cma", label:"CMA"},
        { value:"cna", label:"CNA"},
        { value:"cst", label:"CST"},
        { value:"laboratory", label:"Laboratory"},
        { value:"lvn/pn", label:"LVN/PN"},
        { value:"np", label:"NP"},
        { value:"ot", label:"OT"},
        { value:"pt", label:"PT"},
        { value:"radiology", label:"Radiology"},
        { value:"rn", label:"RN"},
        { value:"rrt", label:"RRT"},
        { value:"slp", label:"SLP"},
        { value:"surg tech", label:"Surg Tech"},
        { value:"therapy", label:"Therapy"},
    ]

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: '#3C59A7',
          color: state.isSelected ? '#ffffff' : '#EDF1FD',
          fontFamily: 'Open Sans',
        }),
        control: () => ({
          display: 'flex',
          alignContent: 'center',
          width: 250,
          border: '1px solid #EDF1FD',
          color: '#EDF1FD',
        }),
        input: () => ({
            display: 'none',
        }),
        placeholder: () => ({
            color: '#EDF1FD',
            fontFamily: 'Open Sans',
        }),
        singleValue: () => ({
            color: '#EDF1FD',
            fontFamily: 'Open Sans',
    
        })
      }

    return (
        <section className={contactForm}>
        <Script src="https://www.google.com/recaptcha/api.js?render=6LdsOx4iAAAAAIsCht7EfyhaR5i_8hES02IoWyju"></Script>
            <div className={contactForm_wrapper}>
                <div className={contactForm_cta}>
                    <div className={contactForm_ctaWrapper}>
                        <h2>Learn more about</h2>
                        <h6>TGL Med Staff</h6>
                        <h3>Call <a href={`tel:${data}`}>{ data.slice(1) }</a></h3>
                    </div>
                </div>
                {!submitted ?
                    <div className={contactForm_form}>
                        <h3 className={contactForm_formCta}>How can we help? Talk to a Recruiter</h3>
                        <form onSubmit={handleOnSubmit}>
                            <div className={contactForm_fields}>
                                <input type="text" name="firstName" placeholder="First Name*" className={contactForm_fields_first} required/>
                                <input type="text" name="lastName" placeholder="Last Name*" className={contactForm_fields_last} required/>
                                <input type="email" name="email" placeholder="Email*" className={contactForm_fields_email} required />
                                <input type="tel" name="phone" placeholder="Phone Number" className={contactForm_fields_phone} />
                                <input type="text" name="city" placeholder="Preferred City" className={contactForm_fields_city} />
                                <input type="text" name="state" placeholder="Preferred State" className={contactForm_fields_state} />
                                <div className={contactForm_fields_prof}>
                                    <Select name="prof" styles={customStyles} options={profList} isSearchable={true} placeholder="Profession" />
                                </div>
                                <input type="text" name="spec" placeholder="Specialization" className={contactForm_fields_spec} />
                                <textarea name="comments" placeholder="Comments" rows="6" className={contactForm_fields_comments} />
                                <div className={contactForm_fields_required}>
                                    {notification && 
                                        <p>{notification}</p>
                                    }
                                </div>
                                <input type="hidden" id="captchaResponse" name="g-recaptcha-response"></input>
                                <input type="submit" value="submit"  
                                  data-sitekey="6LdsOx4iAAAAAIsCht7EfyhaR5i_8hES02IoWyju"
                                  data-callback='onSubmit' 
                                  data-action='submit'
                                  className={`${contactForm_fields_submit}`} />
                            </div>
                        </form>

                    </div>
                    :
                    <div className={contactForm_success} id="travelerSuccess">
                        <div className={contactForm_successText}>
                            <h3>Thank you!</h3>
                            <p>We'll be in touch soon.</p>
                        </div>
                    </div>
                }
            </div>
        </section>

    )
}

export default ContactForm