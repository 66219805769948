import * as React from "react";

import {  StaticImage } from "gatsby-plugin-image";

import { hero, hero_wrapper, hero_title, hero_image, hero_imageAccent,
    imageOverlay, imageOverlay_lg, imageOverlay_sm } from './small-hero.module.scss';

const SmallHero = () => {
    
    return (
        <section className={hero}>
            <div className={hero_wrapper}>
                <div className={hero_title}>
                    <h2>Page not Found</h2>
                </div>
                <div className={hero_image}>
                    <div className={hero_imageAccent}>
                        <StaticImage src="../../assets/images/hero-heart-sm.png" alt="" 
                            className={`${imageOverlay} ${imageOverlay_sm}`}  loading="eager" layout="fullWidth"/>
                        <StaticImage src="../../assets/images/hero-heart.png" alt="" 
                            className={`${imageOverlay} ${imageOverlay_lg}`}  loading="eager"/>
                    </div>
                </div>
            </div>
        </section>

    )

}

export default SmallHero