import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby";

import { Seo } from "../components/Seo/seo";
import Layout from "../components/Layout/layout";

import ContactForm from "../components/ContactForm";
import SmallHero from "../components/SmallHero";

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLocation {
        edges {
          node {
            phoneNumber
          }
        }
      }
    }
  `)

  const { phoneNumber } = data.allContentfulLocation.edges[0].node

  return (
    <>
      <Layout>
        <SmallHero title="Page not found" />
        <div className="fullWidth">
          <p className=" textBlock">
            Sorry, we couldn’t find what you were looking for.
          </p>
          <p className=" textBlock">
            <Link to="/">Go home</Link>.
          </p>
        </div>
        <ContactForm data={phoneNumber} />
      </Layout>
    </>
  )
}
export const Head = () => (
  <Seo 
    title="404: Page not Found"
  />

)

export default NotFoundPage
